<template>
  <div class="move-profile-line-base">
    <div id="move-profile-line-right" class="move-profile-line-right"></div>
    <div id="move-profile-line-under" class="move-profile-line-under"></div>
    <div id="move-profile-line-left" class="move-profile-line-left"></div>
  </div>
  <section id="profile" class="profile">
    <h2 id="profile-title" class="name-image">
      <img src="../assets/name.png" width="180" height="150"/>
    </h2>
    <div class="profile-data">
      <div class="information">
        北海道小樽市出身 札幌市内在住
      </div>
      <div class="itemization">
        ・ぷよぷよが5連鎖以上できない<br />
        ・乃木坂46が大好き<br />
        ・見た目で期待されるが実は小食<br />
        ・エゴサは半年に一回くらい<br />
        ・出前館が大好き<br />
        ・旦那の誕生日が覚えれない<br />
        ・我が家の猫がかわいい。とてもかわいい。<br />
      </div>
      <div class="past">
        幼少期、外で遊ぶことよりも<br />
        絵を描く事が大好きだった<br />
        将来の夢は漫画家になることだったが<br />
        ストーリーを考える能力・文章力が乏しかったため諦める<br />
        <br />
        現在はイラストレーターとして活動中<br />
        ヴァーチャルユーチューバーなどの<br />
        キャラクターデザインを手掛ける<br />
        2020年9月、株式会社イントに所属。<br />
      </div>
      <div class="spec">
        <div class="title">対応コンピュータ</div>
        <div class="value">Windows10</div>
        <div class="title">モニター</div>
        <div class="value">EIZO24インチ</div>
        <div class="title">使用ソフト</div>
        <div class="value">Photoshop</div>
        <div class="title">タブレット</div>
        <div class="value">Wacom Cintiq Pro 24(DTH-2420/K0)</div>
        <div class="title">マウス</div>
        <div class="value">MX ERGO ワイヤレス トラックボール</div>
        <div class="title">プリンター</div>
        <div class="value">Canon PIXUS MG7730</div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import {gsap} from "gsap";

export default class ProfileComponent extends Vue {
  public mounted() {
    this.scrollProfile();
    this.moveMobileProfileBar();
  }

  public scrollProfile() {

    if (window.innerWidth >= 550) {
      gsap.to('#profile', { // 動かしたい要素
        x: '50vw', // 右方向に500動く
        duration: 2, // アニメーションは1秒間
        scrollTrigger: {
          trigger: "#profile",
          start: 'center bottom', // アニメーション開始位置
          // end: 'top top', // アニメーション終了位置
          // markers: true, // マーカー表示
        }
      });

    } else {
      gsap.to('#profile', { // 動かしたい要素
        'margin-left': 'auto', // 右方向に500動く
        duration: 2, // アニメーションは1秒間
        scrollTrigger: {
          trigger: "#profile",
          start: 'center bottom', // アニメーション開始位置
          // end: 'top top', // アニメーション終了位置
          // markers: true, // マーカー表示
        }
      });
    }
  }

  public async moveMobileProfileBar() {

    if (window.innerWidth < 550) {
      await gsap.to('#move-profile-line-right', { // 動かしたい要素
        width: 10,
        scrollTrigger: {
          trigger: "#move-profile-line-right",
          start: 'center center', // アニメーション開始位置
          // end: 'center center', // アニメーション終了位置
          // markers: true, // マーカー表示
          // scrub: true, // アニメーションをスクロール位置にリンクさせる
        }
      });

      await gsap.to('#move-profile-line-under', { // 動かしたい要素
        height: 950,
        scrollTrigger: {
          trigger: "#move-profile-line-under",
          start: 'center center', // アニメーション開始位置
          // end: 'center center', // アニメーション終了位置
          //markers: true, // マーカー表示
          // scrub: true, // アニメーションをスクロール位置にリンクさせる
        }
      });

      await gsap.to('#move-profile-line-left', { // 動かしたい要素
        width: 850,
        scrollTrigger: {
          trigger: "#move-profile-line-left",
          start: 'center center', // アニメーション開始位置
          // end: 'center center', // アニメーション終了位置
          //markers: true, // マーカー表示
          // scrub: true, // アニメーションをスクロール位置にリンクさせる
        }
      });
    }
  }
}
</script>

<style lang='scss' scoped>
@import "../assets/style/global.scss";

.profile {
  max-width: 400px;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 1000px;
  margin-left: -40%;
  margin-top: 100px;

  @media (max-width: 550px) {
    width: 90%;
    height: 1100px;
    margin-left: -100%;
    margin-right: auto;
  }
}

.name-image {
  font-size: 24px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 18px;
  img {
    width: 180px;
  }
}

.information {
  margin-top: 30px;
  line-height: 40px;
  max-width: 270px;
}

.itemization {
  margin-top: 10px;
}

.past {
  margin-top: 20px;
}

.spec {
  margin-top: 30px;
  font-size: 12px;
  max-width: 270px;
  width: 100%;
  line-height: 12px;

  .title {
    margin-bottom: 10px;
  }

  .value {
    margin-bottom: 20px;
    padding-left: 1em;
  }
}

.move-profile-line-base {
  display: none;
  position: relative;

  .move-profile-line-right {
    position: absolute;
    top: 200px;
    right: 0;
    width: 0;
    height: 1px;
    background-color: #6E6E6E;
  }

  .move-profile-line-under {
    position: absolute;
    top: 200px;
    right: 10px;
    width: 1px;
    height: 0;
    background-color: #6E6E6E;
  }

  .move-profile-line-left {
    position: absolute;
    top: 1150px;
    right: 10px;
    width: 0;
    height: 1px;
    background-color: #6E6E6E;
  }

  @media (max-width: 550px) {
    display: block;
  }
}
</style>
