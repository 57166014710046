<template>
  <section id="gallery" class="gallery">
    <div class="gallery-title">
      <h2 id="gallery-title">
        Gallery
      </h2>
    </div>
    <div class="gallery-sample">
      <div class="gallery-sample-move">
        <img src="../assets/gallery/character1.png"/>
        <img src="../assets/gallery/character2.png"/>
        <img src="../assets/gallery/character3.png"/>
        <img src="../assets/gallery/character4.png"/>
        <img src="../assets/gallery/character5.png"/>
        <img src="../assets/gallery/character6.png"/>
        <img src="../assets/gallery/character1.png"/>
        <img src="../assets/gallery/character2.png"/>
        <img src="../assets/gallery/character3.png"/>
        <img src="../assets/gallery/character4.png"/>
        <img src="../assets/gallery/character5.png"/>
        <img src="../assets/gallery/character6.png"/>
        <img src="../assets/gallery/character1.png"/>
        <img src="../assets/gallery/character2.png"/>
        <img src="../assets/gallery/character3.png"/>
        <img src="../assets/gallery/character4.png"/>
        <img src="../assets/gallery/character5.png"/>
        <img src="../assets/gallery/character6.png"/>
      </div>
    </div>
    <div>
      <router-link to="/gallery" class="more-button">
        more
      </router-link>
    </div>
  </section>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import {gsap} from "gsap";

export default class GalleryComponent extends Vue {

  public mounted() {
    gsap.to('.gallery-sample-move', { x: -2047, duration: 20, repeat: -1, ease: 'none' });
  }
}
</script>

<style lang='scss' scoped>
@import "../assets/style/global.scss";
.gallery {
  margin-top: 200px;
}

.gallery-title {
  width: 95%;
  max-width: $max-contents-width;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 720px) {
    text-align: center;
  }
}
.gallery-sample {
  overflow-x: hidden;
  margin-top: 100px;
}
.gallery-sample-move {
  width: 6100px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;

  img {
    flex: 0 0 300px;
    width: 300px;

    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
}

.more-button {
  background-color: #CD5157;
  line-height: 50px;
  color: #ffffff;
  letter-spacing: 6px;
  text-decoration: none;
  font-size: 16px;
  width: 200px;
  border-radius: calc(25px);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 4px 7px rgba(0, 0, 0, 0.2);
}
</style>
