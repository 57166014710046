import { serverTimestamp } from "firebase/firestore";
import {ContactDatabase} from "@/firebase/database/ContactDatabase";
import {FieldValue} from "@firebase/firestore";

export class Contact {
  public name: string | null = null;
  public email: string | null = null;
  public budget: string | null = null;
  public content: string | null = null;
  public createDate: FieldValue;

  private contactDatabase: ContactDatabase = new ContactDatabase();

  constructor(name: string | null,
              email: string | null,
              budget: string | null,
              content: string | null) {
    this.name = name;
    this.email = email;
    this.budget = budget;
    this.content = content;
    this.createDate = serverTimestamp();
  }

  public getName() {
    return this.name;
  }

  public getEmail() {
    return this.email;
  }

  public getBudget() {
    return this.budget;
  }

  public getContent() {
    return this.content;
  }

  public getCreateDate() {
    return this.createDate;
  }

  public getSymbol() {
    return {
      name: this.getName(),
      email: this.getEmail(),
      budget: this.getBudget(),
      content: this.getContent(),
      createDate: this.getCreateDate(),
    };
  }

  public async save() {
    this.createDate = serverTimestamp();
    return await this.contactDatabase.save(this);
  }
}