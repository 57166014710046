import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import {GalleryImage} from "@/models/GalleryImage";

export class GalleryStorage {

  private storageName: string = 'gallery';

  public async getOriginalDownloadURL(galleryImage: GalleryImage) {
    const storage = getStorage();
    return await getDownloadURL(ref(storage, this.storageName + '/original/' + galleryImage.getId() + '/' + galleryImage.getName()));
  }

  public async getThumbnailDownloadURL(galleryImage: GalleryImage) {
    const storage = getStorage();
    return await getDownloadURL(ref(storage, this.storageName + '/thumbnail/' + galleryImage.getId() + '/' + galleryImage.getName()));
  }

  public async save(galleryImage: GalleryImage) {
    const storage = getStorage();
    const originalStorage = ref(storage, this.storageName + '/original/' + galleryImage.getId() + '/' + galleryImage.getName());
    const thumbnailStorage = ref(storage, this.storageName + '/thumbnail/' + galleryImage.getId() + '/' + galleryImage.getName());

    await uploadBytes(originalStorage, galleryImage.getOriginalFile() as Blob);
    await uploadBytes(thumbnailStorage, galleryImage.getThumbnailFile() as Blob);
  }

  public async delete(galleryImage: GalleryImage) {
    const storage = getStorage();
    const originalStorage = ref(storage, this.storageName + '/original/' + galleryImage.getId() + '/' + galleryImage.getName());
    const thumbnailStorage = ref(storage, this.storageName + '/thumbnail/' + galleryImage.getId() + '/' + galleryImage.getName());

    await deleteObject(originalStorage);
    await deleteObject(thumbnailStorage);
  }
}