<template>
  <section id="about" class="about">
    <div class="profile-background">
      <h2 id="about-title-mobile">
        About
      </h2>
    </div>

    <div class="profile">
      <div class="torisan">
        <img src="../assets/torisan.png"/>
      </div>

      <div class="profile-name">
        <div class="job">
          イラストレーター
        </div>
        <div class="name-jp">
          赤渕めがね
        </div>
      </div>
      <div class="profile-sns">
        <a href="https://x.com/abmJP_" target="_blank">
          <img src="../assets/x.png"/>
        </a>
        <a href="https://t.co/aYdXvurBnI?amp=1" target="_blank">
          <img src="../assets/pixiv.png"/>
        </a>
        <a href="https://www.instagram.com/abmjp_/" target="_blank">
          <img src="../assets/Instagram.png"/>
        </a>
      </div>
      <div class="profile-information">
        <div class="information">
          北海道札幌市在住 株式会社int所属 イラストレーター<br />
          高校卒業後趣味としてデジタルイラストをはじめ、<br />
          2019年頃から本格的にイラストレーターとして活動する。<br />
        </div>
        <div class="favorite-title">
          得意科目
        </div>
        <div class="favorite-subject">
          ・キャラクター制作(等身・デフォルメ・動物)<br />
          ・キービジュアル<br />
          ・コンセプトアート<br />
          ・ネームロゴ<br />
          ・三面図<br />
          ・UI<br />
          ・live2D向けイラスト制作<br />
        </div>

        <div class="software-skill">
          <div class="photoshop-skill">
            <img src="../assets/photoshop.png"/>
            <div class="base-bar">
              <div class="progress-bar"></div>
            </div>
          </div>
          <div class="illustrator-skill">
            <img src="../assets/illustrator.png"/>
            <div class="base-bar">
              <div class="progress-bar"></div>
            </div>
          </div>
          <div class="xd-skill">
            <img src="../assets/xd.png"/>
            <div class="base-bar">
              <div class="progress-bar"></div>
            </div>
          </div>
          <div class="rush-skill">
            <img src="../assets/rush.png"/>
            <div class="base-bar">
              <div class="progress-bar"></div>
            </div>
          </div>
        </div>

        <div class="working-days">
          <div class="working">Mon</div>
          <div class="working">Tue</div>
          <div class="working">Wed</div>
          <div class="working">Thu</div>
          <div class="vacation">Fri</div>
          <div class="working">Sat</div>
          <div class="vacation">Sun</div>
        </div>
      </div>
    </div>

    <div class="business-results">
      <div class="character-design">
        <h3>キャラクターデザイン</h3>
        <div class="title">・バーチャルYouTuber 夢羽九</div>
        <div class="value">キャラクターデザイン・live2d・ロゴ制作</div>
        <div class="title">・バーチャルYouTuber 翠音ちこ</div>
        <div class="value">キャラクターデザイン・live2d・ロゴ制作</div>
        <div class="title">・バーチャルYouTuber 猫海ゆず季</div>
        <div class="value">キャラクターデザイン・live2d制作</div>
        <div class="title">・バーチャルYouTuber 杠アカネ</div>
        <div class="value">キャラクターデザイン・live2d制作</div>
        <div class="title">・バーチャルYouTuber 竜宮城ツカ</div>
        <div class="value">キャラクターデザイン・live2d制作</div>
        <div class="title">・バーチャルYouTuber睦月みお</div>
        <div class="value">キャラクターデザイン・live2d制作</div>
        <div class="title">・バーチャルYouTuber浮都々とろろ</div>
        <div class="value">キャラクターデザイン・live2d・ロゴ制作</div>
        <div class="title">・バーチャルYouTuber龍光寺シンバ</div>
        <div class="value">キャラクターデザイン・live2d制作</div>
        <div class="title">・バーチャルYouTuberけろうさぎ。</div>
        <div class="value">キャラクターデザイン・live2d制作</div>
        <div class="title">・IRIAMライバー壱夜澪夜</div>
        <div class="value">キャラクターデザイン・立ち絵制作</div>
      </div>
      <div class="event-related-books">
        <div class="event">
          <h3>イラスト制作</h3>
          <div class="title">・北海道小樽市企画イベント「アニパ」内 イラストレーター展示会</div>
          <div class="value">ポスターイラスト制作</div>
          <div class="title">・バーチャル物産展 切り抜き酒 第二弾・第三弾・第四弾・第五弾</div>
          <div class="value">ラベルイラスト制作</div>
          <div class="title">・ライバー事務所cozopro 特別企画餃子9</div>
          <div class="value">キービジュアル制作(SDキャラクター)</div>
          <div class="title">・ライバー事務所cozopro 特別企画周年祭2024</div>
          <div class="value">キービジュアル制作</div>
          <div class="title">・女神メイドコンセプトカフェ「Artemis ChuChu」</div>
          <div class="value">看板イラスト制作</div>
        </div>
      </div>
      <div class="related-books">
        <h3>関連書籍</h3>
        <div class="title">
          ・画集「PIXIV GIRL X GIRL COLLECTION 2020 MARGUERITE」掲載
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {Vue} from 'vue-class-component';

export default class AboutComponent extends Vue {
}
</script>

<style lang='scss' scoped>
@import "../assets/style/global.scss";

.about {
}

.profile-background {
  color: #ffffff;
  text-align: center;
  background-image: url("../assets/profile-backimage.png");
  background-size: calc(100% + 200px) 100%;
  background-position: -200px 0;
  padding-top: 200px;
  padding-bottom: 200px;
  margin-bottom: -200px;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.torisan {
  width: 300px;
  text-align: center;
  img {
    width: 60%;
  }
}

.profile-name {
  flex: 1 1 100%;
  text-align: center;
  width: 100%;

  .job {
    margin-top: 50px;
    font-size: 16px;
    letter-spacing: 10px;
  }

  .name-jp {
    margin-top: 5px;
    font-size: 35px;
    letter-spacing: 15px;
  }
}

.profile-sns {
  width: 250px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 40px;
  }
}

.profile-name-sns {
  margin-left: auto;
  border-bottom: 1px solid #ffffff;
  display: flex;
  align-items: flex-end;
  width: calc(100% - 200px);


  img {
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
}


.profile-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 370px;
}

.information {
  margin-top: 40px;
  line-height: 22px;
}
.favorite-title {
  margin-top: 30px;
  width: 370px;
 }
.favorite-subject {
  margin-top: 10px;
  width: 370px;
}

.software-skill {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  img {
    width: 100%;
  }
}

.base-bar {
  background-color: #D4D4D4;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.progress-bar {
  background-color: #CD5157;
  width: 100%;
  height: 10px;
  border-radius: 5px;
}

.photoshop-skill {
  flex: 0 0 50px;
  margin-left: 20px;
  margin-right: 20px;

  .progress-bar {
    width: 80%;
  }
}

.illustrator-skill {
  flex: 0 0 50px;
  margin-left: 20px;
  margin-right: 20px;

  .progress-bar {
    width: 60%;
  }
}

.xd-skill {
  flex: 0 0 50px;
  margin-left: 20px;
  margin-right: 20px;

  .progress-bar {
    width: 80%;
  }
}

.rush-skill {
  flex: 0 0 50px;
  margin-left: 20px;
  margin-right: 20px;

  .progress-bar {
    width: 90%;
  }
}

.working-days {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  padding-right: 30px;
  color: #ffffff;

  .working {
    font-weight: bold;
    text-align: center;
    width: 40px;
    line-height: 40px;
    height: 40px;
    font-size: 14px;
    border-radius: 50%;
    background-color: #CD5157;
    box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.16);
    margin-left: 5px;
    margin-right: 5px;
  }

  .vacation {
    font-weight: bold;
    text-align: center;
    width: 40px;
    line-height: 40px;
    height: 40px;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 50%;
    color: #C8B1B2;
    box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.16);
    margin-left: 5px;
    margin-right: 5px;
  }
}


.business-results {
  width: 95%;
  max-width: $max-contents-width;
  margin-right: auto;
  margin-left: auto;
  color: $black-font-color;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    margin-top: 100px;
  }

  .title {
    margin-top: 20px;
    line-height: 30px;
  }

  .value {
    line-height: 30px;
    padding-left: 1em;
  }
}
</style>
