import { getApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, orderBy, getDoc, doc, setDoc, addDoc } from "firebase/firestore";
import {GalleryImage} from "@/models/GalleryImage";
import {GalleryImageList} from "@/models/GalleryImageList";
import {Firestore} from "@firebase/firestore";

export class GalleryDatabase {

  private tableName: string = 'gallery-images';
  private db: Firestore;

  constructor() {
    const app = getApp();
    this.db = getFirestore(app);
  }

  public async findAll(): Promise<GalleryImageList> {
    const docRef = collection(this.db, this.tableName);

    const galleryImageEntityList = await getDocs(query(docRef, orderBy('order', 'desc')));

    const galleryImageList = [];

    for(let i = 0; i < galleryImageEntityList.size; i++) {
      const galleryImageDoc = galleryImageEntityList.docs[i];
      const galleryImageData = galleryImageDoc.data();

      const galleryImage = new GalleryImage(
        galleryImageDoc.id,
        null,
        null,
        galleryImageData.originalFileUrl,
        galleryImageData.thumbnailFileUrl,
        galleryImageData.name,
        galleryImageData.explanation,
        galleryImageData.order,
      );
      galleryImageList.push(galleryImage);
    }
    return new GalleryImageList(galleryImageList);
  }

  public async find(imageId: string): Promise<GalleryImage> {
    const docRef = doc(this.db, this.tableName, imageId);
    const galleryImageDoc = await getDoc(docRef);

    const galleryImageData = galleryImageDoc.data();

    if (galleryImageData == null) {
      throw "error";
    }
    return new GalleryImage(
      galleryImageDoc.id,
      null,
      null,
      galleryImageData.originalFileUrl,
      galleryImageData.thumbnailFileUrl,
      galleryImageData.name,
      galleryImageData.explanation,
      galleryImageData.order,
    );
  }

  public async save(galleryImage: GalleryImage) {
    if (galleryImage.isNew()) {
      const docRef = collection(this.db, this.tableName);
      const result = await addDoc(docRef, galleryImage.getSymbol());
      return result.id;
    }

    const docRef = doc(this.db, this.tableName, galleryImage.getId() as string);
    await setDoc(docRef, galleryImage.getSymbol(), {merge:true});
    return galleryImage.getId();
  }
}