import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-dcf9fee6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("ul", null, [
      _createElementVNode("li", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickMenu('TOP')))
      }, " Top "),
      _createElementVNode("li", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clickMenu('ABOUT')))
      }, " About "),
      _createElementVNode("li", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clickMenu('GALLERY')))
      }, " Gallery "),
      _createElementVNode("li", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.clickMenu('WORKING_FLOW')))
      }, " Working Flow "),
      _createElementVNode("li", {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clickMenu('CONTACT')))
      }, " Contact ")
    ])
  ]))
}