<template>
  <section id="working-flow" class="working-flow">
    <h2 id="working-flow-title" class="working-flow-title">
      Working Flow
    </h2>
    <div class="working-flow-number">
      <img src="../assets/working_flow/working-flow-number.png"/>
    </div>
    <div class="working-flow-detail">
      <div class="working-flow-detail-item">
        <img src="../assets/working_flow/workflow-image-1.png"/>
        <div>
          ・どのようなイラストが必要か<br />
          （画像や自作のイラストでも可）<br />
          ・形式やサイズ（横長・縦長など）<br />
          ・使用用途や場面<br />
          ・希望の納期<br />
          ・ご予算<br />
          <br />
          上記をご記入の上、【Contact】からメールをお願いいたします。<br />
          Xのダイレクトメールでも受け付けております。<br />
          3日以内に返信がない場合 再度ご連絡をお願いします<br />
          <br />
          ※必ずご連絡できるメールアドレスをご利用ください<br />
        </div>
      </div>

      <div class="working-flow-detail-item">
        <img src="../assets/working_flow/workflow-image-2.png"/>
        <div>
          いただいた内容をもとに見積もりを作成し連絡いたします。<br />
          金額やイラストのイメージなどをすり合わせをいたします。<br />
          数回やり取りを経て 作業に取り掛かります。<br />
        </div>
      </div>

      <div class="working-flow-detail-item">
        <img src="../assets/working_flow/workflow-image-3.png"/>
        <div>
          作品の大まかな構図を制作します。<br />
          完成後、メールでデータを共有いたします。<br />
          必要に応じて修正や調整などを行います。<br />
          <br />
          作業期間：3～5日間<br />
          リテイク回数：～5回<br />
          ※イラストの内容によって作業予定日数が変わります。<br />
        </div>
      </div>

      <div class="working-flow-detail-item">
        <img src="../assets/working_flow/workflow-image-4.png"/>
        <div>
          線画を清書し、色付けをしていきます。<br />
          完成後、メールでデータを共有いたします。<br />
          必要に応じて修正や調整などを行います。<br />
          <br />
          作業期間：5～9日間<br />
          リテイク回数：～3回<br />
          ※イラストの内容によって作業予定日数が変わります。<br />
          ※構図変更の場合、有償での対応となります。<br />
        </div>
      </div>

      <div class="working-flow-detail-item">
        <img src="../assets/working_flow/workflow-image-5.png"/>
        <div>
          修正等が完了次第、納品となります。<br />
          アップローダーやドライブなどでデータをお渡しいたします。<br />
          <br />
          納品後、請求書をお送りいたします。<br />
          お支払いは【銀行振り込み】でお願いしております。<br />
          請求書に記載された期限内にお振込みをお願いいたします。<br />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {Vue} from 'vue-class-component';

export default class WorkingFlowComponent extends Vue {
}
</script>

<style lang='scss' scoped>
@import "../assets/style/global.scss";

.working-flow {
  width: 95%;
  max-width: $max-contents-width;
  margin-right: auto;
  margin-left: auto;
  margin-top: 200px;
}

.working-flow-number {
  width: 92%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 100px;

  img {
    width: 100%;
  }
}

.working-flow-detail {
  display: flex;
  justify-content: space-between;
}

.working-flow-detail-item {
  flex: 0 1 200px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 16px;

  img {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 60px;
    border-radius: 12px;
    box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.2);
  }
}
</style>
