import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-45dc3d2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "index" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FirstLoading = _resolveComponent("FirstLoading")!
  const _component_MenuPc = _resolveComponent("MenuPc")!
  const _component_MenuMobile = _resolveComponent("MenuMobile")!
  const _component_Top = _resolveComponent("Top")!
  const _component_AboutPc = _resolveComponent("AboutPc")!
  const _component_AboutMobile = _resolveComponent("AboutMobile")!
  const _component_Gallery = _resolveComponent("Gallery")!
  const _component_WorkingFlowPc = _resolveComponent("WorkingFlowPc")!
  const _component_WorkingFlowMobile = _resolveComponent("WorkingFlowMobile")!
  const _component_Contact = _resolveComponent("Contact")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_Transition, { name: "first-loading" }, {
      default: _withCtx(() => [
        (_ctx.showFirstLoading)
          ? (_openBlock(), _createBlock(_component_FirstLoading, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_MenuPc, { class: "pc-only" }),
    _createVNode(_component_MenuMobile, { class: "mobile-only" }),
    _createVNode(_component_Top),
    _createVNode(_component_AboutPc, { class: "pc-only" }),
    _createVNode(_component_AboutMobile, { class: "mobile-only" }),
    _createVNode(_component_Gallery),
    _createVNode(_component_WorkingFlowPc, { class: "pc-only" }),
    _createVNode(_component_WorkingFlowMobile, { class: "mobile-only" }),
    _createVNode(_component_Contact)
  ]))
}