<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss">
@import "./assets/style/global.scss";

html, body {
  margin: 0;
  font-size: 14px;
  background-color: $base-background-color;
  overflow-x: hidden;

  font-family: 'kozuka-gothic-pro', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif', sans-serif;
}
</style>
