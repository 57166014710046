import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-349332e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "admin-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/admin",
      class: "title"
    }, {
      default: _withCtx(() => [
        _createTextVNode("akabuti.com管理画面")
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      to: "/admin/image",
      class: "item"
    }, {
      default: _withCtx(() => [
        _createTextVNode("画像管理")
      ]),
      _: 1
    }),
    _createElementVNode("a", {
      href: "#",
      class: "item",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logOut()))
    }, "ログアウト")
  ]))
}