<template>
  <section class="index">
    <BaseLayout>
      <div class="admin-image-index">
        画像管理画面トップページ
        <router-link to="/admin/image/new" class="title">画像追加</router-link>
      </div>
      <div class="gallery-image-list">
        <div v-for="galleryImage in galleryImageList.getList()" :key="galleryImage.getId()" class="gallery-image-item">
          <router-link :to="{name: 'admin-image-edit', params: {imageId: galleryImage.getId()}}" class="image-item">
            <img :src="galleryImage.getThumbnailFileUrl()" class="gallery-image">
            {{galleryImage.getName()}}
          </router-link>
        </div>
      </div>
    </BaseLayout>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseLayout from '@/components/admin/common/BaseLayoutComponent.vue'
import {GalleryDatabase} from "@/firebase/database/GalleryDatabase";
import {GalleryImageList} from "@/models/GalleryImageList";

@Options({
  components: {
    BaseLayout,
  },
})
export default class IndexView extends Vue {

  public galleryDatabase: GalleryDatabase = new GalleryDatabase();
  public galleryImageList: GalleryImageList = new GalleryImageList([]);

  public async created() {
    this.galleryImageList = await this.galleryDatabase.findAll();
  }
}
</script>

<style lang='scss' scoped>
@import "@/assets/style/global.scss";

.index {
}

.gallery-image-list {
  display: flex;
  flex-wrap: wrap;
}

.gallery-image-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.gallery-image {
  width: 150px;
  height: 150px;
  border: 1px solid black;
  object-fit: contain;
}

.image-item {
  display: flex;
  flex-direction: column;
}

</style>
