import { getApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import {Contact} from "@/models/Contact";
import {Firestore} from "@firebase/firestore";

export class ContactDatabase {

  private db: Firestore;
  private tableName: string = 'contacts';

  constructor() {
    const app = getApp();
    this.db = getFirestore(app);
  }
  public async save(contact: Contact) {
    const docRef = collection(this.db, this.tableName);
    const snapshot = await addDoc(docRef, contact.getSymbol());
    return snapshot.id;
  }
}