<template>
  <div class="admin-menu">
    <router-link to="/admin" class="title">akabuti.com管理画面</router-link>
    <router-link to="/admin/image" class="item">画像管理</router-link>
    <a href="#" class="item" @click="logOut()">ログアウト</a>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import { getAuth } from "firebase/auth";

export default class AdminMenuComponent extends Vue {

  public async logOut() {
    await getAuth().signOut();
    this.$router.push("/admin/log_in");
  }
}
</script>

<style lang='scss' scoped>
  .admin-menu {
    display: flex;
    flex-direction: column;
    background-color: #191970;
    color: #ffffff;
    height: 100%;
  }
  .title {
    display: block;
    padding: 20px;
    border-bottom: 1px solid #666666;
    color: #ffffff;
    text-decoration: none;
  }

  .item {
    display: block;
    padding: 10px 10px 10px 20px;
    border-bottom: 1px solid #666666;
    color: #ffffff;
    text-decoration: none;
  }
</style>
