<template>
  <section id="contact" class="contact">
    <div class="contact-block">
      <h2 id="contact-title" class="contact-title">
        Contact
      </h2>

      <div class="contact-background" >
        <div class="contact-contents">
          <div class="request-work">
            <div class="information">個人様・企業様<br />お仕事随時募集しております。</div>
            <div class="worker-item">・オリジナルキャラクターデザイン</div>
            <div class="worker-item">・SNSで使うアイコン制作</div>
            <div class="worker-item">・小説・雑誌・HPなどの挿絵</div>
            <div class="worker-item">・ポスターイラスト</div>
            <div class="worker-item">※その他イラスト制作作業</div>

            <div class="image-sample-button-block">
              <div class="image-sample-button" @click="openImageSample">
                イメージサンプルはこちら！
              </div>
            </div>
            <div class="torisan">
              <img src="../assets/torisan.png"/>
            </div>
          </div>

          <form @submit.prevent="openContactConfirm"  class="contact-input-form">
            <div class="contact-input-block">
              <div class="contact-input">
                <span class="title">お名前</span>
                <input type="text" v-model="name" required />
              </div>
              <div class="contact-input">
                <span class="title">メールアドレス</span>
                <input type="email" v-model="email" required />
              </div>
              <div class="contact-input">
                <span class="title">ご予算</span>
                <input type="text" v-model="budget" required />
              </div>
              <div class="contact-input-textarea">
                <span class="title">ご依頼内容</span>
                <textarea rows="20" v-model="content" required />
              </div>
            </div>
            <div class="send-contact-button-block">
              <button type="submit" class="send-contact-button">
                内容を確認する
              </button>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </div>

    <div class="contact-confirm-block" v-if="showContactConfirm" @click="closeContactConfirm">
      <div class="contact-confirm" @click="$event.stopPropagation();">
        <div class="contact-confirm-information">
          以下の内容で問題がなければ、<br class="mobile-only" />送信を押してください。
        </div>
        <div class="contact-confirm-item-block">
          <div class="contact-confirm-item">
            <span class="title">お名前</span>
            <div class="value">
              {{name}}
            </div>
          </div>
          <div class="contact-confirm-item">
            <span class="title">メールアドレス</span>
            <div class="value">
              {{email}}
            </div>
          </div>
          <div class="contact-confirm-item">
            <span class="title">ご予算</span>
            <div class="value">
              {{budget}}
            </div>
          </div>
          <div class="contact-confirm-item">
            <span class="title">内容</span>
            <div class="value">
              {{ content }}
            </div>
          </div>
        </div>
        <div class="send-contact-button-block">
          <button type="button" class="send-contact-button" @click="sendContact">
            送信
          </button>
          <div @click="closeContactConfirm" class="close-contract-confirm-link">
            内容を変更する
          </div>
        </div>
      </div>
    </div>

    <div class="contact-complete-block" v-if="showContactComplete" @click="closeContactComplete">
      <div class="contact-complete" @click="$event.stopPropagation();">
        <div class="contact-complete-information">
          メール送信完了いたしました<br />
          返信があるまでお待ちください<br />
          <br />
          ３日以上連絡がない場合、お手数ですが<br v-if="windowSize >= 550"/><a href="https://twitter.com/abmJP_" target="_blank">X（旧Twitter）</a>のリポスト・DM等で連絡をお願いいたします
        </div>
        <div>
          <img src="../assets/torisan-ojigi.png"/>
        </div>
        <div @click="closeContactComplete" class="close-contract-complete-link">
          閉じる
        </div>
      </div>
    </div>

    <div class="image-sample-block" v-if="showImageSample" @click="closeImageSample">
      <div class="image-sample-close-block">
        <div class="image-sample-close" @click="closeImageSample">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 71.408 71.408">
            <path id="times-solid" d="M49.239,115.7l20.3-20.3a6.381,6.381,0,0,0,0-9.023l-4.512-4.512a6.381,6.381,0,0,0-9.023,0l-20.3,20.3-20.3-20.3a6.381,6.381,0,0,0-9.023,0L1.868,86.38a6.381,6.381,0,0,0,0,9.023l20.3,20.3L1.868,136a6.381,6.381,0,0,0,0,9.023l4.512,4.512a6.381,6.381,0,0,0,9.023,0l20.3-20.3,20.3,20.3a6.381,6.381,0,0,0,9.023,0l4.512-4.512a6.381,6.381,0,0,0,0-9.023Z" transform="translate(0 -80)" fill="#fff"/>
          </svg>
        </div>
      </div>

      <div class="image-sample" @click="$event.stopPropagation();">
        <div class="image-sample-information">
          <div class="title">
            {{imageSampleTitles[imageSampleIndex]}}
          </div>
          <div class="explanation">
            {{imageSampleExplanations[imageSampleIndex]}}
          </div>
        </div>
        <div class="images">
          <div v-for="imageSampleImageUrl in imageSampleImageUrls[imageSampleIndex]" :key="imageSampleImageUrl" class="image">
            <img :src="imageSampleImageUrl"/>
          </div>
        </div>
        <div class="prev-icon" @click="prevImageSample" v-if="imageSampleIndex != 0">
          <font-awesome-icon :icon="['fas', 'angle-left']" />
        </div>
        <div class="next-icon" @click="nextImageSample" v-if="imageSampleIndex != 3">
          <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
        <div class="selector">
          <div @click="moveImageSample(0)" :class="imageSampleIndex == 0 ? 'select-sample-image': ''"></div>
          <div @click="moveImageSample(1)" :class="imageSampleIndex == 1 ? 'select-sample-image': ''"></div>
          <div @click="moveImageSample(2)" :class="imageSampleIndex == 2 ? 'select-sample-image': ''"></div>
          <div @click="moveImageSample(3)" :class="imageSampleIndex == 3 ? 'select-sample-image': ''"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Contact} from "@/models/Contact";
import ContactMailFunction from "../firebase/functions/ContactMailFunction";
import FooterComponent from "@/components/FooterComponent.vue";
import AboutMobile from "@/components/AboutMobileComponent.vue";
@Options({
  components: {
    AboutMobile,
    Footer: FooterComponent,
  },
})

export default class ContactComponent extends Vue {
  public showContactConfirm: boolean = false;
  public showContactComplete: boolean = false;
  public showImageSample: boolean = false;
  public windowSize: number = 0;
  public imageSampleIndex: number = 0;

  public name: string | null = null;
  public email: string | null = null;
  public budget: string | null = null;
  public content: string | null = null;

  public imageSampleExplanations: string[] = [
    "人物のみ\n（ポーズ・棒立ち・その他）",
    "人物＋背景ファンタジー系\n（柄・ニュアンス・簡易室内・自然・建物）",
    "三面図",
    "（カラー・モノクロ）",
  ]
  public imageSampleTitles: string[] = [
    "等身キャラクター",
    "等身キャラクター",
    "等身キャラクター",
    "デフォルメキャラクター",
  ]
  public imageSampleImageUrls: string[][] = [
    [
      "/contact/contact1.png",
      "/contact/contact2.png",
    ],
    [
      "/contact/contact3.png",
      "/contact/contact4.png",
    ],
    [
      "/contact/contact5.png",
    ],
    [
      "/contact/contact6.png",
    ],
  ]

  public created() {
    this.windowSize = window.innerWidth;
  }

  public openContactConfirm() {
    this.showContactConfirm = true;
  }
  public openImageSample() {
    this.imageSampleIndex = 0;
    this.showImageSample = true;
  }

  public closeContactConfirm() {
    this.showContactConfirm = false;
  }

  public closeContactComplete() {
    this.showContactComplete = false;
  }

  public closeImageSample() {
    this.showImageSample = false;
  }

  public prevImageSample() {
    this.imageSampleIndex = this.imageSampleIndex - 1;
  }

  public nextImageSample() {
    this.imageSampleIndex = this.imageSampleIndex + 1;
  }

  public moveImageSample(index: number) {
    this.imageSampleIndex = index;
  }

  public async sendContact() {
    const contact = new Contact(this.name, this.email, this.budget, this.content);
    const contactId = await contact.save();

    //メール送信
    const contactMailFunction = new ContactMailFunction();
    contactMailFunction.execute(contactId);

    this.showContactConfirm = false;
    this.showContactComplete = true;
  }
}
</script>

<style lang='scss' scoped>
@import "../assets/style/global.scss";

.contact {
}

.contact-title {
  width: 95%;
  max-width: $max-contents-width;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 720px) {
    text-align: center;
  }
}

.contact-block {
  margin-top: 100px;
}

.contact-background {
  background-image: url("../assets/contact-backimage.png");
  background-size: 100% 100%;
  padding-top: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 720px) {
    padding-top: 50px;
    background-size: 100% 300px;
    background-repeat: no-repeat;
    background-position: 0 100%;
  }
}

.contact-contents {
  max-width: $max-contents-width;
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: stretch;

  @media (max-width: 720px) {
    flex-direction: column;
  }
}

.request-work {
  flex: 0 0 45%;
  background-color: #D84F55;
  box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  position: relative;

  .information {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 4px;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 30px;

    @media (max-width: 720px) {
      text-align: center;
      line-height: 30px;
    }
  }

  .worker-item {
    margin-bottom: 10px;
    padding-left: 1em;
    margin-left: 50px;
    margin-right: 50px;
  }

  .image-sample-button-block {
    margin-top: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 720px) {
      margin-top: 50px;
      margin-bottom: 100px;
    }
  }

  .image-sample-button {
    text-align: center;
    line-height: 50px;
    width: 250px;
    background: linear-gradient(to right, #CB70FF, #FF6060);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }

  .torisan {
    position: absolute;
    bottom: -50px;
    left: -30px;

    width: 250px;

    @media (max-width: 720px) {
      width: 150px;
      left: auto;
      right: 0;
      bottom: -50px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.contact-input-form {
  flex: 0 0 45%;
  background-color: #ffffff;
  box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.2);

  @media (max-width: 720px) {
    background-color:transparent;
    box-shadow: 0 0 0;
  }
}

.contact-input-block {
  margin: 20px 50px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 720px) {
    flex-direction: column;
  }
}

.contact-input {
  flex: 0 0 47%;
  display: flex;
  flex-direction: column;

  margin-top: 30px;

  .title {
    display: inline-block;
  }

  input {
    font-size: 16px;
    border: 0;
    border-bottom: 1px solid #000000;

    @media (max-width: 720px) {
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
      border-bottom: 0;
      border-radius: 20px;
      line-height: 40px;
      margin-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.contact-input-textarea {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  font-size: 16px;

  textarea {
    @media (max-width: 720px) {
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
      border: 0;
      border-radius: 20px;
      margin-top: 10px;
      padding: 20px;
    }
  }
}

.send-contact-button-block {
  text-align: center;
  margin-bottom: 30px;
}

.send-contact-button {
  width: 250px;
  background-color: #2E51B1;
  border: 0;
  line-height: 40px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 40px;
  text-align: center;
  letter-spacing: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.close-contract-confirm-link {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  color: #B73E44;
  border-bottom: 1px solid #B73E44;
  width: 100px;
  cursor: pointer;
}

.contact-confirm-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 30;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-confirm {
  background-color: #ffffff;
  width: 80%;
  height: 80%;
  box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-confirm-information {
  margin: 20px;

  @media (max-width: 720px) {
    text-align: center;
  }
}

.contact-confirm-item-block {
  width: 70%;
  height: 60%;
  overflow-y: scroll;
  padding: 20px;
  box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.2);

  @media (max-width: 720px) {
    text-align: center;
    box-shadow: 0 0 0;
    padding: 10px;
  }

  .value {
    margin-left: 20px;
    margin-bottom: 20px;
    white-space: pre-wrap;

    @media (max-width: 720px) {
      margin-left: 0;
    }
  }
}

.contact-complete-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 30;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close-contract-complete-link {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  color: #B73E44;
  border-bottom: 1px solid #B73E44;
  text-align: center;
  width: 50px;
  cursor: pointer;
}

.contact-complete {
  background-color: #ffffff;
  width: 80%;
  height: 60%;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 300px;

    @media (max-width: 810px) {
      width: 200px;
    }
  }
}

.contact-complete-information {
  text-align: center;
  margin: 20px;
}

.image-sample-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 30;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-sample-close-block {
  width: 85%;
  text-align: right;

  display: flex;
  flex-direction: row-reverse;
}

.image-sample-close {
  background-color: #B73E44;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.image-sample {
  background-color: #ffffff;
  width: 80%;
  height: 80%;
  box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  position: relative;

  .image-sample-information {
    display: flex;
    align-items: flex-start;

    margin-left: 40px;
    margin-top: 20px;
    margin-right: 40px;

    @media (max-width: 720px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .title {
    background-color: #B73E44;
    color: #ffffff;
    line-height: 50px;
    border-radius: 25px;
    letter-spacing: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;

    flex: 0 0 260px;

    @media (max-width: 720px) {
      font-size: 14px;
      letter-spacing: 3px;
      flex: 0 0 auto;
      width: 220px;
    }
  }

  .explanation {
    margin-left: 20px;
    margin-top: 10px;
    white-space: pre-wrap;

    @media (max-width: 720px) {
      margin-left: 0;
    }
  }

  .images {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: calc(90% - 70px);

    @media (max-width: 720px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .image {
    margin-left: 20px;
    margin-right: 20px;
    height: 100%;

    @media (max-width: 720px) {
      max-height: 40%;
      margin-top: 10px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .prev-icon {
    background-color: #B73E44;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    color: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: -25px;
    top: calc(50% - 30px);
    svg {
      width: 40px;
      height: 40px;
    }
  }

  .next-icon {
    background-color: #B73E44;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    color: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    right: -25px;
    top: calc(50% - 30px);

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .selector {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: calc(50% - 120px);
    bottom: -40px;
    div {
      margin-left: 20px;
      margin-right: 20px;

      background-color: #ffffff;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      cursor: pointer;
    }

    .select-sample-image {
      background-color: #B73E44;
    }
  }
}
</style>
