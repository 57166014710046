import {GalleryDatabase} from "@/firebase/database/GalleryDatabase";
import {GalleryStorage} from "@/firebase/storage/GalleryStorage";

export class GalleryImage {
  public id: string | null;
  public originalFile: Blob | null;
  public thumbnailFile: Blob | null;
  public originalFileUrl: string | null;
  public thumbnailFileUrl: string | null;
  public name: string;
  public explanation: string | null;
  public order: number;

  private galleryDatabase: GalleryDatabase = new GalleryDatabase();
  private galleryStorage: GalleryStorage = new GalleryStorage();

  constructor(id: string | null,
              originalFile: Blob | null,
              thumbnailFile: Blob | null,
              originalFileUrl: string | null,
              thumbnailFileUrl: string | null,
              name: string,
              explanation: string | null,
              order: number) {
    this.id = id;
    this.originalFile = originalFile;
    this.thumbnailFile = thumbnailFile;
    this.originalFileUrl = originalFileUrl;
    this.thumbnailFileUrl = thumbnailFileUrl;
    this.name = name;
    this.explanation = explanation;
    this.order = order;
  }

  public getId() {
    return this.id;
  }

  public getOriginalFile() {
    return this.originalFile;
  }

  public getThumbnailFile() {
    return this.thumbnailFile;
  }

  public getOriginalFileUrl() {
    return this.originalFileUrl;
  }

  public getThumbnailFileUrl() {
    return this.thumbnailFileUrl;
  }

  public getName() {
    return this.name;
  }

  public getExplanation() {
    return this.explanation;
  }

  public getOrder() {
    return this.order;
  }

  public isNew() {
    return this.id == null;
  }

  public getSymbol() {
    return {
      name: this.getName(),
      explanation: this.getExplanation(),
      order: this.getOrder(),
      originalFileUrl: this.getOriginalFileUrl(),
      thumbnailFileUrl: this.getThumbnailFileUrl(),
    };
  }

  public async save() {
    this.id = await this.galleryDatabase.save(this);

    if (this.originalFile != null) {
      await this.galleryStorage.save(this);
      await this.saveDownloadURL();
    }
  }

  public async saveDownloadURL() {
    this.originalFileUrl = await this.galleryStorage.getOriginalDownloadURL(this);
    this.thumbnailFileUrl = await this.galleryStorage.getThumbnailDownloadURL(this);
    await this.galleryDatabase.save(this);
  }

}