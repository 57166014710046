<template>
  <section class="index">
    <transition name="first-loading">
      <FirstLoading v-if="showFirstLoading" />
    </transition>
    <MenuPc class="pc-only" />
    <MenuMobile class="mobile-only" />
    <Top />
    <AboutPc class="pc-only" />
    <AboutMobile class="mobile-only" />
    <Gallery />
    <WorkingFlowPc class="pc-only" />
    <WorkingFlowMobile class="mobile-only" />
    <Contact />
  </section>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import FirstLoadingComponent from '@/components/FirstLoadingComponent.vue';
import MenuPcComponent from '@/components/MenuPcComponent.vue';
import TopComponent from '@/components/TopComponent.vue';
import ProfileComponent from '@/components/ProfileComponent.vue';
import AboutPcComponent from "@/components/AboutPcComponent.vue";
import GalleryComponent from "@/components/GalleryComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import WorkingFlowPcComponent from "@/components/WorkingFlowPcComponent.vue";
import MenuMobileComponent from "@/components/MenuMobileComponent.vue";
import AboutMobileComponent from "@/components/AboutMobileComponent.vue";
import WorkingFlowMobileComponent from "@/components/WorkingFlowMobileComponent.vue";

@Options({
  components: {
    WorkingFlowPc: WorkingFlowPcComponent,
    WorkingFlowMobile: WorkingFlowMobileComponent,
    FirstLoading: FirstLoadingComponent,
    MenuPc: MenuPcComponent,
    MenuMobile: MenuMobileComponent,
    Top: TopComponent,
    Profile: ProfileComponent,
    AboutPc: AboutPcComponent,
    AboutMobile: AboutMobileComponent,
    Gallery: GalleryComponent,
    Contact: ContactComponent,
  },
})
export default class IndexView extends Vue {
  public showFirstLoading: boolean = true;
  public windowSize: number = 0;

  public created() {
    this.windowSize = window.innerWidth;
    gsap.registerPlugin(ScrollTrigger);
    setTimeout( () => {
      this.showFirstLoading = false;
    }, 500);
  }

  public mounted() {
    const move = this.$route.query.move
    if (move == null) {
      return;
    }
    if (move == 'TOP') {
      window.scrollTo({ top: 0 });
    } else if (move == 'ABOUT') {
      const element = document.getElementById('about-title') as Element;
      const rect = element.getBoundingClientRect();
      window.scrollBy({ top: rect.top - 100 });

    } else if (move == 'WORKING_FLOW') {
      const element = document.getElementById('working-flow-title') as Element;
      const rect = element.getBoundingClientRect();
      window.scrollBy({ top: rect.top - 100 });

    } else if (move == 'GALLERY') {
      const element = document.getElementById('gallery-title') as Element;
      const rect = element.getBoundingClientRect();
      window.scrollBy({ top: rect.top - 100 });

    } else if (move == 'CONTACT') {
      const element = document.getElementById('contact-title') as Element;
      const rect = element.getBoundingClientRect();
      window.scrollBy({ top: rect.top - 100 });

    }
  }
}
</script>

<style lang='scss' scoped>
@import "@/assets/style/global.scss";

.index {
}

.first-loading-leave-active {
  transition: opacity 3s;
}

.first-loading-leave-to {
  opacity: 0;
}
</style>
