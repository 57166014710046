<template>
  <section id="about" class="about">
    <div class="profile-background">
      <div class="profile">
        <h2 id="about-title">
          About
        </h2>
        <div class="profile-name-sns">
          <div class="profile-name">
            <div class="job">
              イラストレーター
            </div>
            <div class="name-jp">
              赤渕めがね
            </div>
            <div>
              <div class="name-en">
                Akabuti Megane
              </div>
            </div>
          </div>
          <div class="profile-sns">
            <a href="https://x.com/abmJP_" target="_blank">
              <img src="../assets/x.png"/>
            </a>
            <a href="https://t.co/aYdXvurBnI?amp=1" target="_blank">
              <img src="../assets/pixiv.png"/>
            </a>
            <a href="https://www.instagram.com/abmjp_/" target="_blank">
              <img src="../assets/Instagram.png"/>
            </a>
          </div>
        </div>
        <div class="profile-information">
          <div class="torisan">
            <img src="../assets/torisan.png"/>
          </div>
          <div>
            <div class="information">
              北海道札幌市在住 株式会社int所属 イラストレーター<br />
              高校卒業後趣味としてデジタルイラストをはじめ、2019年頃から本格的にイラストレーターとして活動する。<br />
              得意科目<br />
              ・キャラクター制作(等身・デフォルメ・動物)<br />
              ・キービジュアル<br />
              ・コンセプトアート<br />
              ・ネームロゴ<br />
              ・三面図<br />
              ・UI<br />
              ・live2D向けイラスト制作<br />
            </div>

            <div class="software-skill">
              <div class="photoshop-skill">
                <img src="../assets/photoshop.png"/>
                <div class="base-bar">
                  <div class="progress-bar"></div>
                </div>
              </div>
              <div class="illustrator-skill">
                <img src="../assets/illustrator.png"/>
                <div class="base-bar">
                  <div class="progress-bar"></div>
                </div>
              </div>
              <div class="xd-skill">
                <img src="../assets/xd.png"/>
                <div class="base-bar">
                  <div class="progress-bar"></div>
                </div>
              </div>
              <div class="rush-skill">
                <img src="../assets/rush.png"/>
                <div class="base-bar">
                  <div class="progress-bar"></div>
                </div>
              </div>
            </div>

            <div class="working-days">
              <div class="working">Mon</div>
              <div class="working">Tue</div>
              <div class="working">Wed</div>
              <div class="working">Thu</div>
              <div class="vacation">Fri</div>
              <div class="working">Sat</div>
              <div class="vacation">Sun</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="business-results">
      <div class="character-design">
        <h3>キャラクターデザイン</h3>
        <div class="border"></div>
        <div class="title">・バーチャルYouTuber 夢羽九</div>
        <div class="value">キャラクターデザイン・live2d・ロゴ制作</div>
        <div class="title">・バーチャルYouTuber 翠音ちこ</div>
        <div class="value">キャラクターデザイン・live2d・ロゴ制作</div>
        <div class="title">・バーチャルYouTuber 猫海ゆず季</div>
        <div class="value">キャラクターデザイン・live2d制作</div>
        <div class="title">・バーチャルYouTuber 杠アカネ</div>
        <div class="value">キャラクターデザイン・live2d制作</div>
        <div class="title">・バーチャルYouTuber 竜宮城ツカ</div>
        <div class="value">キャラクターデザイン・live2d制作</div>
        <div class="title">・バーチャルYouTuber睦月みお</div>
        <div class="value">キャラクターデザイン・live2d制作</div>
        <div class="title">・バーチャルYouTuber浮都々とろろ</div>
        <div class="value">キャラクターデザイン・live2d・ロゴ制作</div>
        <div class="title">・バーチャルYouTuber龍光寺シンバ</div>
        <div class="value">キャラクターデザイン・live2d制作</div>
        <div class="title">・バーチャルYouTuberけろうさぎ。</div>
        <div class="value">キャラクターデザイン・live2d制作</div>
        <div class="title">・IRIAMライバー壱夜澪夜</div>
        <div class="value">キャラクターデザイン・立ち絵制作</div>
      </div>
      <div class="event-related-books">
        <div class="event">
          <h3>イラスト制作</h3>
          <div class="border"></div>
          <div class="title">・北海道小樽市企画イベント「アニパ」内 イラストレーター展示会</div>
          <div class="value">ポスターイラスト制作</div>
          <div class="title">・バーチャル物産展 切り抜き酒 第二弾・第三弾・第四弾・第五弾</div>
          <div class="value">ラベルイラスト制作</div>
          <div class="title">・ライバー事務所cozopro 特別企画餃子9</div>
          <div class="value">キービジュアル制作(SDキャラクター)</div>
          <div class="title">・ライバー事務所cozopro 特別企画周年祭2024</div>
          <div class="value">キービジュアル制作</div>
          <div class="title">・女神メイドコンセプトカフェ「Artemis ChuChu」</div>
          <div class="value">看板イラスト制作</div>
        </div>
        <div class="related-books">
          <h3>関連書籍</h3>
          <div class="border"></div>
          <div class="title">
            ・画集「PIXIV GIRL X GIRL COLLECTION 2020 MARGUERITE」掲載
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {Vue} from 'vue-class-component';

export default class AboutComponent extends Vue {
}
</script>

<style lang='scss' scoped>
@import "../assets/style/global.scss";

.about {
  color: #ffffff;
}

.profile-background {
  background-image: url("../assets/profile-backimage.png");
  background-size: 100% 100%;
  padding-top: 350px;
  padding-bottom: 300px;
}

.profile {
  width: 95%;
  max-width: $max-contents-width;
  margin-right: auto;
  margin-left: auto;
}

.profile-name-sns {
  margin-left: auto;
  border-bottom: 1px solid #ffffff;
  display: flex;
  align-items: flex-end;
  width: calc(100% - 200px);


  img {
    width: 40px;
    margin-left: 20px;
    margin-right: 20px;

    @media (max-width: 920px) {
      width: 30px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.profile-name {
  flex: 1 1 100%;
}

.job {
  font-size: 22px;
  letter-spacing: 10px;
}

.name-jp {
  font-size: 40px;
  letter-spacing: 15px;
  padding-left: 130px;

  @media (max-width: 920px) {
    padding-left: 30px;
    font-size: 30px;
  }
}

.name-en {
  font-size: 22px;
  letter-spacing: 10px;
  margin-left: auto;
  width: 320px;
}

.profile-sns {
  flex: 0 0 240px;
}

.profile-information {
  display: flex;
  justify-content: space-between;
}

.torisan {
  flex: 0 0 250px;
  img {
    width: 100%;
  }
}

.information {
  margin-left: auto;
  margin-top: 40px;
  max-width:  750px;
  padding-left: 30px;
  line-height: 22px;
}

.software-skill {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;

  img {
    width: 100%;
  }
}

.base-bar {
  background-color: #D4D4D4;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.progress-bar {
  background-color: #CD5157;
  width: 100%;
  height: 10px;
  border-radius: 5px;
}

.photoshop-skill {
  margin-left: 20px;
  margin-right: 20px;
  flex: 0 0 60px;

  @media (max-width: 920px) {
    flex: 0 0 40px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .progress-bar {
    width: 80%;
  }
}

.illustrator-skill {
  margin-left: 20px;
  margin-right: 20px;
  flex: 0 0 60px;

  @media (max-width: 920px) {
    flex: 0 0 40px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .progress-bar {
    width: 60%;
  }
}

.xd-skill {
  margin-left: 20px;
  margin-right: 20px;
  flex: 0 0 60px;

  @media (max-width: 920px) {
    flex: 0 0 40px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .progress-bar {
    width: 80%;
  }
}

.rush-skill {
  margin-left: 20px;
  margin-right: 20px;
  flex: 0 0 60px;

  @media (max-width: 920px) {
    flex: 0 0 40px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .progress-bar {
    width: 90%;
  }
}

.working-days {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  padding-right: 30px;

  @media (max-width: 920px) {
    padding-right: 0;
  }

  .working {
    font-size: 20px;
    font-weight: bold;
    line-height: 60px;
    text-align: center;
    width: 60px;
    border-radius: 50%;
    background-color: #CD5157;
    box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.16);
    margin-left: 10px;
    margin-right: 10px;

    @media (max-width: 920px) {
      width: 40px;
      line-height: 40px;
      height: 40px;
      font-size: 16px;
    }
  }

  .vacation {
    font-size: 20px;
    font-weight: bold;
    line-height: 60px;
    text-align: center;
    width: 60px;
    background-color: #ffffff;
    border-radius: 50%;
    color: #C8B1B2;
    box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.16);
    margin-left: 10px;
    margin-right: 10px;

    @media (max-width: 920px) {
      width: 40px;
      line-height: 40px;
      height: 40px;
      font-size: 16px;
    }
  }
}


.business-results {
  width: 95%;
  max-width: $max-contents-width;
  margin-right: auto;
  margin-left: auto;
  color: $black-font-color;

  display: flex;
  justify-content: space-around;

  h3 {
  }

  .title {
    margin-top: 20px;
    line-height: 30px;
  }

  .value {
    line-height: 30px;
    padding-left: 1em;
  }
}

.border {
  border-top: 20px solid #E9E9E9;
  margin-top: -30px;
  margin-left: -20px;
}

.character-design {
  flex: 0 1 40%;
}

.event-related-books {
  flex: 0 1 40%;
}

</style>
