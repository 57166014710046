import {GalleryImage} from "@/models/GalleryImage";

export class GalleryImageList {
  public imageList: GalleryImage[];

  constructor(imageList: GalleryImage[]) {
    if (imageList == null) {
      this.imageList = [];
    } else {
      this.imageList = imageList;
    }
  }

  public getList() {
    return this.imageList;
  }

  public count() {
    return this.imageList.length;
  }

  public isEmpty() {
    return this.imageList.length === 0;
  }
}