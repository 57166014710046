<template>
  <section class="index">
    <BaseLayout>
      <div class="edit-image">
        画像管理画面詳細

        <a href="#" role="button" @click.prevent="showFileChooser">
          ファイルをアップロード
        </a>
        <input
            ref="input"
            type="file"
            name="image"
            accept="image/*"
            @change="setImage"
            class="input-image-file"
        />

        <div v-if="baseImageFile != null">
          <vue-cropper
              ref="cropper"
              :src="thumbnailFile"
              :auto-crop-area="1"
              preview=".preview"
              :movable="false"
              :zoomable="false"
              :scalable="false"
              :rotatable="false"
              v-show="false"
          />

          <div class="preview-block">
            サムネイル
            <div class="preview" />
          </div>

          <div class="input-block">
            <div class="title">
              画像タイトル
            </div>
            <input type="text" v-model="imageFileName" required />
          </div>
          <div class="input-block">
            <div class="title">
              画像説明文
            </div>
            <textarea v-model="imageFileExplanation"  rows="5" cols="50"/>
          </div>
          <div>
            <a href="#" role="button" @click.prevent="saveFile">
              画像を保存
            </a>
          </div>
        </div>
      </div>
    </BaseLayout>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseLayout from '@/components/admin/common/BaseLayoutComponent.vue'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import {GalleryImage} from "@/models/GalleryImage";
import {GalleryDatabase} from "@/firebase/database/GalleryDatabase";

@Options({
  components: {
    BaseLayout,
    VueCropper,
  },
})
export default class NewView extends Vue {
  public imageFileName: string = "";
  public imageFileExplanation: string = "";
  public baseImageFile: File | null = null;
  public thumbnailFile: File | null = null;

  public galleryDatabase: GalleryDatabase = new GalleryDatabase();

  public showFileChooser() {
    (this.$refs as any).input.click();
  }

  public setImage(e: any) {
    const file = e.target.files[0];
    if (file.type.indexOf('image/') === -1) {
      alert('Please select an image file');
      return;
    }
    this.imageFileName = file.name;
    this.baseImageFile = file;

    if (typeof FileReader === 'function') {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target != null) {
          if (this.baseImageFile != null) {
            (this.$refs as any).cropper.replace(event.target.result);
          }
        }
      };
      reader.readAsDataURL(file);
    } else {
      alert('Sorry, FileReader API not supported');
    }
  }

  public async saveFile() {
    (this.$refs as any).cropper.getCroppedCanvas({width: 400, height: 400}).toBlob(async (blob: Blob) => {
      const galleryImageList = await this.galleryDatabase.findAll();
      let galleryImage = new GalleryImage(
          null,
          this.baseImageFile,
          blob,
          null,
          null,
          this.imageFileName,
          this.imageFileExplanation,
          galleryImageList.count());

      await galleryImage.save();
      this.$router.push("/admin/image");
    });
  }
}
</script>

<style lang='scss' scoped>
@import "@/assets/style/global.scss";

.edit-image {
  max-width: 500px;
}

.input-image-file {
  display: none;
}

.preview {
  width: 300px;
  height: 300px;
  overflow: hidden;
  border: 1px solid black;
}

.preview-block {
  margin-top: 40px;
}

.input-block {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;

  .title {
    width: 150px;
  }
}
</style>
