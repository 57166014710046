<template>
  <section class="first-loading">
    <div class="top-image">
<!--      <img src="../assets/top.jpg"/>-->
    </div>
<!--    <div class="title-block">-->
<!--      <div class="title">-->
<!--        <img src="../assets/title.png"/>-->
<!--      </div>-->
<!--    </div>-->
  </section>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    msg: String
  }
})
export default class FirstLoadingComponent extends Vue {
}
</script>

<style lang='scss' scoped>
@import "@/assets/style/global.scss";

.first-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 30;
}

.top-image {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
  }
}

.title-block {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .title {
    width: 100%;
    text-align: center;
    padding: 20px;
    border-top: 3px solid #000000;
    border-bottom: 3px solid #000000;

    img {
      height: 10vh;
      max-width: 80vw;
      object-fit: contain;
    }
  }
}

</style>
