import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/IndexView.vue'
import GalleryView from '../views/GalleryView.vue'
import AdminIndexView from '../views/admin/IndexView.vue'
import AdminLogInView from '../views/admin/LogInView.vue'
import AdminImageIndexView from '../views/admin/image/IndexView.vue'
import AdminImageNewView from '../views/admin/image/NewView.vue'
import AdminImageEditView from '../views/admin/image/EditView.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: GalleryView
  },
  {
    path: '/admin/log_in',
    name: 'admin-log-in',
    component: AdminLogInView,
  },
  {
    path: '/admin',
    name: 'admin-index',
    component: AdminIndexView,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/image',
    name: 'admin-image-index',
    component: AdminImageIndexView,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/image/new',
    name: 'admin-image-new',
    component: AdminImageNewView,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/image/:imageId',
    name: 'admin-image-edit',
    component: AdminImageEditView,
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
