<template>
  <section class="menu">
    <button class="menu-trigger" :class="{ active: showMenu }" @click="toggleMenu">
      <span></span>
      <span></span>
      <span></span>
    </button>

    <transition name="menu-transition">
      <div class="menu-contents" v-if="showMenu">
        <ul>
          <li @click="clickMenu('TOP')">
            Top
          </li>
          <li @click="clickMenu('ABOUT')">
            About
          </li>
          <li @click="clickMenu('GALLERY')">
            Gallery
          </li>
          <li @click="clickMenu('WORKING_FLOW')">
            Working Flow
          </li>
          <li @click="clickMenu('CONTACT')">
            Contact
          </li>
        </ul>
      </div>
    </transition>
  </section>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class MenuComponent extends Vue {
  public showMenu: boolean = false;
  public toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  public clickMenu(select: string) {
    if (select == 'TOP') {
      window.scrollTo({ top: 0, behavior:'smooth' });
    } else if (select == 'ABOUT') {
      const element = document.getElementById('about-title-mobile') as Element;
      const rect = element.getBoundingClientRect();
      window.scrollBy({ top: rect.top - 100, behavior:'smooth' });

    } else if (select == 'WORKING_FLOW') {
      const element = document.getElementById('working-flow-title-mobile') as Element;
      const rect = element.getBoundingClientRect();
      window.scrollBy({ top: rect.top - 100, behavior:'smooth' });

    } else if (select == 'GALLERY') {
      const element = document.getElementById('gallery-title') as Element;
      const rect = element.getBoundingClientRect();
      window.scrollBy({ top: rect.top - 100, behavior:'smooth' });

    } else if (select == 'CONTACT') {
      const element = document.getElementById('contact-title') as Element;
      const rect = element.getBoundingClientRect();
      window.scrollBy({ top: rect.top - 100, behavior:'smooth' });

    }

    this.toggleMenu();
  }
}
</script>

<style lang='scss' scoped>
@import "@/assets/style/global.scss";

.menu {
  text-align: right;
  height: 50px;
}

.menu-transition-enter-active {
  transform: translateX(100vw);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.menu-transition-enter-to {
  transform: translateX(0);
}

.menu-transition-leave-active {
  transform: translateX(0);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.menu-transition-leave-to {
  transform: translateX(100vw);
}

.menu-contents {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-size: 30px;

  ul {
    list-style: none;
  }

  li {
    cursor: pointer;
  }

  a {
    color: #ffffff;
    text-decoration: none;
  }
}

// ハンバーガーメニュー用スタイル
.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
}
.menu-trigger {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 30px;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;
  z-index: 20;
}
.menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 4px;
}
.menu-trigger span:nth-of-type(1) {
  top: 0;
}
.menu-trigger span:nth-of-type(2) {
  top: 14px;
}
.menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.menu-trigger span:nth-of-type(1) {
  animation: menu-bar01 .75s forwards;
}
@keyframes menu-bar01 {
  0% {
    transform: translateY(13px) rotate(45deg);
  }
  50% {
    transform: translateY(13px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
.menu-trigger span:nth-of-type(2) {
  transition: all .25s .25s;
  opacity: 1;
}
.menu-trigger span:nth-of-type(3) {
  animation: menu-bar02 .75s forwards;
}
@keyframes menu-bar02 {
  0% {
    transform: translateY(-13px) rotate(-45deg);
  }
  50% {
    transform: translateY(-13px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
.menu-trigger.active span:nth-of-type(1) {
  animation: active-menu-bar01 .75s forwards;
}
@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(13px) rotate(0);
  }
  100% {
    transform: translateY(13px) rotate(45deg);
  }
}
.menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
}
.menu-trigger.active span:nth-of-type(3) {
  animation: active-menu-bar03 .75s forwards;
}
@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-13px) rotate(0);
  }
  100% {
    transform: translateY(-13px) rotate(-45deg);
  }
}
</style>
