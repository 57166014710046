<template>
  <section class="footer">
    <div>©akabuti.com</div>
    <div>当サイト内のすべてのイラストと文字の転載はご遠慮ください</div>
  </section>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class FooterComponent extends Vue {
}
</script>

<style lang='scss' scoped>
@import "../assets/style/global.scss";

.footer {
  line-height: 30px;
  text-align: center;
  height: 100px;
  margin-top: 200px;
  color: #ffffff;

  @media (max-width: 550px) {
    font-size: 12px;
  }
}
</style>
