<template>
  <section class="index">
    <BaseLayout>
      <div class="admin-index">
        管理画面トップページ
      </div>
    </BaseLayout>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseLayout from '@/components/admin/common/BaseLayoutComponent.vue'

@Options({
  components: {
    BaseLayout,
  },
})
export default class IndexView extends Vue {
}
</script>

<style lang='scss' scoped>
@import "@/assets/style/global.scss";

.index {
}
</style>
