<template>
  <section class="menu">
    <ul>
      <li @click="clickMenu('TOP')">
        Top
      </li>
      <li @click="clickMenu('ABOUT')">
        About
      </li>
      <li @click="clickMenu('GALLERY')">
        Gallery
      </li>
      <li @click="clickMenu('WORKING_FLOW')">
        Working Flow
      </li>
      <li @click="clickMenu('CONTACT')">
        Contact
      </li>
    </ul>
  </section>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class MenuComponent extends Vue {

  public clickMenu(select: string) {
    if (select == 'TOP') {
      window.scrollTo({ top: 0, behavior:'smooth' });
    } else if (select == 'ABOUT') {
      const element = document.getElementById('about-title') as Element;
      const rect = element.getBoundingClientRect();
      window.scrollBy({ top: rect.top - 100, behavior:'smooth' });

    } else if (select == 'WORKING_FLOW') {
      const element = document.getElementById('working-flow-title') as Element;
      const rect = element.getBoundingClientRect();
      window.scrollBy({ top: rect.top - 100, behavior:'smooth' });

    } else if (select == 'GALLERY') {
      const element = document.getElementById('gallery-title') as Element;
      const rect = element.getBoundingClientRect();
      window.scrollBy({ top: rect.top - 100, behavior:'smooth' });

    } else if (select == 'CONTACT') {
      const element = document.getElementById('contact-title') as Element;
      const rect = element.getBoundingClientRect();
      window.scrollBy({ top: rect.top - 100, behavior:'smooth' });

    }
  }
}
</script>

<style lang='scss' scoped>
@import "@/assets/style/global.scss";

.menu {
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 15px;
  color: $black-font-color;

  ul {
    display: flex;
    justify-content: center;
    max-width: 1300px;
    width: 80%;
    line-height: 48px;
    border-radius: 24px;
    background-color: #ffffff;
    box-shadow: 30px 20px 30px rgba(0, 0, 0, 0.06);
    margin-top: 30px;
  }

  li {
    list-style:none;
    flex: 0 1 140px;
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
    text-align: center;

    @media (max-width: 980px) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
</style>
