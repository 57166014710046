<template>
  <section class="top">
    <div class="top-image">
      <img src="../assets/top.png" class="pc-only" />
      <img src="../assets/top-mobile.png" class="mobile-only" />
    </div>
  </section>
</template>

<script lang="ts">
import {Vue} from 'vue-class-component';

export default class TopComponent extends Vue {
}
</script>

<style lang='scss' scoped>
@import "../assets/style/global.scss";
.top {
  background-image: url("../assets/top-backimage.png");
  background-size: 100% auto;
  background-position: 0 50%;
  background-repeat: no-repeat;
  margin-bottom: 100px;

  @media (max-width: 720px) {
    background-image: url("../assets/top-backimage-mobile.png");
  }
}
.top-image {
  position: relative;
  width: 60%;
  max-width: $max-contents-width;
  margin-right: auto;
  margin-left: auto;
  padding-top: 150px;

  @media (max-width: 720px) {
    width: 100%;
    padding-top: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
