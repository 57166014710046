<template>
  <div class="working-flow-background">
    <section id="working-flow" class="working-flow">
      <h2 id="working-flow-title-mobile" class="working-flow-title">
        Working Flow
      </h2>
      <div class="working-flow-detail">
        <div class="information">
          作業開始から完成までの<br />大まかな流れです
        </div>
        <div class="working-flow-detail-item">
          <div class="number-border-block">
            <img src="../assets/working_flow/number-1.svg"/>
            <div class="border"></div>
          </div>
          <div class="explanation-block">
            ・どのようなイラストが必要か<br />
            （画像や自作のイラストでも可）<br />
            ・形式やサイズ（横長・縦長など）<br />
            ・使用用途や場面<br />
            ・希望の納期<br />
            ・ご予算<br />
            <br />
            上記をご記入の上、【Contact】からメールをお願いいたします。<br />
            Xのダイレクトメールでも受け付けております。<br />
            3日以内に返信がない場合 再度ご連絡をお願いします<br />
            <br />
            ※必ずご連絡できるメールアドレスをご利用ください<br />
          </div>
        </div>

        <div class="working-flow-detail-item">
          <div class="number-border-block">
            <img src="../assets/working_flow/number-2.svg"/>
            <div class="border"></div>
          </div>
          <div class="explanation-block">
            いただいた内容をもとに見積もりを作成し連絡いたします。<br />
            数回メールでのやり取りを経て、イラスト・スケジュール・金額などを確定をいたします。<br />
          </div>
        </div>

        <div class="working-flow-detail-item">
          <div class="number-border-block">
            <img src="../assets/working_flow/number-3.svg"/>
            <div class="border"></div>
          </div>
          <div class="explanation-block">
            作品の大まかな構図を制作します。<br />
            完成後、メールでデータを共有いたします。<br />
            必要に応じて修正や調整などを行います。<br />
            <br />
            作業期間：3～5日間<br />
            リテイク回数：～5回<br />
            ※イラストの内容によって作業予定日数が変わります。<br />
          </div>
        </div>

        <div class="working-flow-detail-item">
          <div class="number-border-block">
            <img src="../assets/working_flow/number-4.svg"/>
            <div class="border"></div>
          </div>
          <div class="explanation-block">
            線画を清書し、色付けをしていきます。<br />
            完成後、メールでデータを共有いたします。<br />
            必要に応じて修正や調整などを行います。<br />
            <br />
            作業期間：5～9日間<br />
            リテイク回数：～3回<br />
            ※イラストの内容によって作業予定日数が変わります。<br />
            ※構図変更の場合、有償での対応となります。<br />
          </div>
        </div>

        <div class="working-flow-detail-item">
          <div class="number-border-block">
            <img src="../assets/working_flow/number-5.svg"/>
          </div>
          <div class="explanation-block">
            修正等が完了次第、納品となります。<br />
            アップローダーやドライブなどでデータをお渡しいたします。<br />
            <br />
            納品後、請求書をお送りいたします。<br />
            お支払いは【銀行振り込み】でお願いしております。<br />
            請求書に記載された期限内にお振込みをお願いいたします。<br />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import {Vue} from 'vue-class-component';

export default class WorkingFlowComponent extends Vue {
}
</script>

<style lang='scss' scoped>
@import "../assets/style/global.scss";
.working-flow-background {
  background-image: url("../assets/profile-backimage.png");
  background-size: calc(100% + 200px) 500px;
  background-repeat: no-repeat;
  background-position: -200px 50%;
}

.working-flow {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 200px;

  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  padding: 50px 10px;
  background-color: rgba(255, 255, 255, 0.6);
}

.working-flow-title {
  text-align: center;
}

.working-flow-detail {
  margin-top: 50px;
}

.information {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 80px;
  letter-spacing: 3px;
}

.working-flow-detail-item {
  margin-bottom: 100px;
  font-size: 14px;

  display: flex;
  justify-content: space-between;
  min-height: 100px;
}

.number-border-block {
  flex: 0 0 110px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -50px;

  img {
    width: 210px;
    margin-right: -50px;
    margin-left: -50px;
  }

  .border {
    width: 1px;
    height: 100%;
    border-left: 2px dotted #707070;
    margin-bottom: -70px;
    margin-top: -30px;
    margin-left: -20px;
  }
}

.explanation-block {
  flex: 0 0 calc(100% - 110px);
}

</style>
