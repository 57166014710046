<template>
  <div id="base-layout">
    <AdminMenu class="menu"/>
    <div class="contents">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {Options, Vue} from 'vue-class-component';
import AdminMenu from '@/components/admin/common/AdminMenuComponent.vue'

@Options({
  components: {
    AdminMenu,
  },
})
export default class BaseLayoutComponent extends Vue {
}
</script>

<style lang='scss' scoped>
#base-layout {
  font-family: 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', 'sans-serif', sans-serif;
  display: flex;
  height: 100%;
}

.menu {
  position: fixed;
  width: 200px;
}

.contents {
  margin-left: 200px;
  padding: 8px;
  line-height: 30px;
  flex: 1 1 auto;
}
</style>
