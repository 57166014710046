import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7b897af2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "index" }
const _hoisted_2 = { class: "admin-image-index" }
const _hoisted_3 = { class: "gallery-image-list" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseLayout = _resolveComponent("BaseLayout")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_BaseLayout, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(" 画像管理画面トップページ "),
          _createVNode(_component_router_link, {
            to: "/admin/image/new",
            class: "title"
          }, {
            default: _withCtx(() => [
              _createTextVNode("画像追加")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.galleryImageList.getList(), (galleryImage) => {
            return (_openBlock(), _createElementBlock("div", {
              key: galleryImage.getId(),
              class: "gallery-image-item"
            }, [
              _createVNode(_component_router_link, {
                to: {name: 'admin-image-edit', params: {imageId: galleryImage.getId()}},
                class: "image-item"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: galleryImage.getThumbnailFileUrl(),
                    class: "gallery-image"
                  }, null, 8, _hoisted_4),
                  _createTextVNode(" " + _toDisplayString(galleryImage.getName()), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}