<template>
  <section class="login">
    <h1>ログイン</h1>

    <form @submit.prevent="loginEmail">
      <div class="input-block">
        <span class="input-title">
          メールアドレス
        </span>
        <input type="email" v-model="email" required />
      </div>
      <div class="input-block">
        <span class="input-title">
          パスワード
        </span>
        <input type="password" v-model="password" required />
      </div>
      <input type="submit" class="log-in-button" value="ログイン"/>
    </form>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

@Options({
  components: {
  },
})
export default class LogInView extends Vue {
  public email: string = '';
  public password: string = '';

  public async loginEmail() {
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, this.email, this.password);
      this.$router.push("/admin");
    } catch (e) {
      alert("メールアドレスかパスワードが間違っています。");
    }
  }
}
</script>

<style lang='scss' scoped>
@import "@/assets/style/global.scss";

.login {
}
</style>
