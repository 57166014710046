<template>
  <section class="index">
    <div class="pc-only">
      <section class="menu">
        <ul>
          <li>
            <router-link to="/?move=TOP">
              Top
            </router-link>
          </li>
          <li>
            <router-link to="/?move=ABOUT">
              About
            </router-link>
          </li>
          <li>
            <router-link to="/?move=GALLERY">
              Gallery
            </router-link>
          </li>
          <li>
            <router-link to="/?move=WORKING_FLOW">
              Working Flow
            </router-link>
          </li>
          <li>
            <router-link to="/?move=CONTACT">
              Contact
            </router-link>
          </li>
        </ul>
      </section>
    </div>

    <div class="mobile-only">
      <section class="back-button">
        <router-link to="/?move=GALLERY">
          <font-awesome-icon :icon="['fas', 'angle-left']" />
        </router-link>
      </section>
    </div>

    <section class="gallery-title">
      <h2>
        gallery
      </h2>
    </section>
    <section class="gallery-list">
      <div v-for="galleryImage in galleryImageList.getList()" :key="galleryImage.getId()" class="gallery-image-item">
        <img :src="galleryImage.getThumbnailFileUrl()" class="gallery-image" @click="selectGalleryImage(galleryImage)">
      </div>
    </section>

    <div class="modal-background" v-if="selectImage != null" @click="closeDialog()">
      <div class="modal" @click="$event.stopPropagation();">
        <div class="modal-close" @click="closeDialog()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 71.408 71.408">
            <path id="times-solid" d="M49.239,115.7l20.3-20.3a6.381,6.381,0,0,0,0-9.023l-4.512-4.512a6.381,6.381,0,0,0-9.023,0l-20.3,20.3-20.3-20.3a6.381,6.381,0,0,0-9.023,0L1.868,86.38a6.381,6.381,0,0,0,0,9.023l20.3,20.3L1.868,136a6.381,6.381,0,0,0,0,9.023l4.512,4.512a6.381,6.381,0,0,0,9.023,0l20.3-20.3,20.3,20.3a6.381,6.381,0,0,0,9.023,0l4.512-4.512a6.381,6.381,0,0,0,0-9.023Z" transform="translate(0 -80)" fill="#fff"/>
          </svg>
        </div>
        <div class="image-block">
          <div class="modal-gallery-image">
            <img :src="selectImage?.getOriginalFileUrl()">
          </div>
          <div class="image-information">
            <div class="image-title">
              {{selectImage.getName()}}
            </div>
            <div class="image-explanation">
              {{selectImage.getExplanation()}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
import {GalleryDatabase} from "@/firebase/database/GalleryDatabase";
import {GalleryImageList} from "@/models/GalleryImageList";
import {GalleryImage} from "@/models/GalleryImage";

export default class IndexView extends Vue {
  public galleryDatabase: GalleryDatabase = new GalleryDatabase();
  public galleryImageList: GalleryImageList = new GalleryImageList([]);
  public selectImage: GalleryImage | null = null;

  public async created() {
    this.galleryImageList = await this.galleryDatabase.findAll();
  }

  public selectGalleryImage(galleryImage: GalleryImage) {
    this.selectImage = galleryImage;
  }

  public closeDialog() {
    this.selectImage = null;
  }
}
</script>

<style lang='scss' scoped>
@import "@/assets/style/global.scss";

.index {
}


.menu {
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 15px;
  color: $black-font-color;

  ul {
    display: flex;
    justify-content: center;
    max-width: 1300px;
    width: 80%;
    line-height: 48px;
    border-radius: 24px;
    background-color: #ffffff;
    box-shadow: 30px 20px 30px rgba(0, 0, 0, 0.06);
    margin-top: 30px;
  }

  li {
    list-style:none;
    flex: 0 1 140px;
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
    text-align: center;

    @media (max-width: 980px) {
      margin-left: 10px;
      margin-right: 10px;
    }

    a {
      text-decoration: none;
      color: #000000;
    }
  }
}

.back-button {
  background-color: #B73E44;
  position: fixed;
  top: 20px;
  left: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #ffffff;
    width: 50px;
    height: 50px;
  }
}

.gallery-title {
  padding-top: 150px;
  text-align: center;

  @media (max-width: 720px) {
    padding-top: 100px;
  }
}

.gallery-list {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  max-width: $max-contents-width;
  margin-left: auto;
  margin-right: auto;
  padding-top: 150px;
  padding-bottom: 200px;

  @media (max-width: 720px) {
    padding-top: 100px;
  }
}

.gallery-image-item {
  width: 300px;
  height: 300px;
  flex: 0 0 300px;
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 720px) {
    width: 38%;
    flex: 0 0 38%;
    margin-bottom: 30px;
    margin-left: 5%;
    margin-right: 5%;
  }

  .gallery-image {
    max-width: 100%;
    max-height: 100%;

    object-fit: contain;
    cursor: pointer;
    box-shadow: 18px 10px 20px rgba(0, 0, 0, 0.2);
  }
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 30;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 80%;
  max-width: 1100px;
  height: 70%;
  background-color: #ffffff;

  @media (max-width: 720px) {
    height: 90%;
    padding-bottom: 30px;
  }

  .image-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(100% - 60px);
    margin-left: 20px;
    margin-right: 20px;

    @media (max-width: 720px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .modal-gallery-image {
      flex: 0 0 60%;
      width: 60%;
      height: 90%;
      object-fit: contain;

      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 720px) {
        margin-top: 20px;
        width: 90%;
        height: 60%;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        box-shadow: 18px 10px 20px rgba(0, 0, 0, 0.2);
      }
    }

    .image-information {
      flex: 0 0 35%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      @media (max-width: 720px) {
        flex: 0 0 auto;
        margin-top: 50px;
      }
    }

    .image-title {
      font-size: 16px;
    }

    .image-explanation {
      margin-top: 20px;
      white-space: pre-wrap;
    }
  }
}

.modal-close {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #707070;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 20px 20px 0 auto;

  cursor: pointer;
}
</style>
