import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { getAuth } from "firebase/auth";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';

library.add(fas, far, fab)

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};
const app = initializeApp(firebaseConfig);
getAnalytics(app);
getFirestore(app);
getFunctions(app);

//ログイン制御
router.beforeEach((to, from, next) => {

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        const user = getAuth().currentUser;
        if(!user) {
          alert("ログインしてください。");
          next({ path: '/admin/log_in'});
          return;
        }
        next();

      } else {
        alert("ログインしてください。");
        next({ path: '/admin/log_in'});
      }
    });
  } else {
    next();
  }
});

createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).mount('#app');
